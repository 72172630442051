<template>
  <v-container class="contenedor-main">
    <v-row>
      <v-col cols="12" md="12">
        <v-data-table
          :headers="headers"
          :items="ips"
          dense
          class="elevation-1"
          no-results-text="Sin resultados"
          :header-props="{ sortByText: 'Organizar por' }"
          loading-text="Cargando información ..."
          :search="search"
          :footer-props="{
            'items-per-page-text': 'items por página',
            'items-per-page-all-text': 'Todos',
            'items-per-page-options': [-1, 5, 10, 15],
          }"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-card-title>
                <v-text-field
                  v-model="searchIp"
                  @click:append="filterIP"
                  @submit.prevent="filterIP"
                  append-icon="mdi-magnify"
                  append-outer-icon="mdi-autorenew"
                  label="Buscar por ip"
                  @click:append-outer="initialize"
                  single-line
                  hide-details
                ></v-text-field>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  append-outer-icon="mdi-autorenew"
                  @click:append-outer="initialize"
                  label="Buscar por docente"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
            </v-toolbar>
          </template>
          <template v-slot:[`item.direcciones`]="{ item }">
            <v-list>
              <v-list-item
                v-for="(data, index) in item.direcciones"
                :key="data + index"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="data.ip"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-row justify="space-around">
                    <v-btn icon>
                      <v-icon
                        small
                        :color="
                          data.status == 0
                            ? 'grey'
                            : data.status == -1
                            ? 'red'
                            : 'grey'
                        "
                        @click="bloquearIp(data.ip)"
                        >mdi-cancel</v-icon
                      >
                    </v-btn>
                    <v-btn icon>
                      <v-icon
                        small
                        :color="data.status == 0 ? 'orange' : 'grey'"
                        @click="advertirIp(data.ip)"
                        >mdi-alert-circle-outline</v-icon
                      >
                    </v-btn>
                    <v-btn icon>
                      <v-icon
                        small
                        :color="
                          data.status == 0
                            ? 'grey'
                            : data.status == 1
                            ? 'green'
                            : 'grey'
                        "
                        @click="permitirIp(data.ip)"
                        >mdi-check</v-icon
                      >
                    </v-btn>
                  </v-row>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-switch v-model="modeloBloqueo" :label="item" inset> </v-switch>
          </template>
          <template v-slot:no-data>
            <v-row>
              <v-col cols="12" md="12">
                <v-container>
                  <v-progress-circular
                    v-if="ips.length == 0"
                    :width="3"
                    :size="20"
                    color="primary"
                    indeterminate
                  >
                  </v-progress-circular>
                </v-container>
              </v-col>
              <v-col cols="12" md="12">
                <v-btn
                  small
                  v-bind:style="{
                    'margin-top': '10px',
                    'margin-bottom': '10px',
                  }"
                  color="primary"
                  @click="initialize"
                >
                  Volver a cargar
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
        <div v-if="ips.length != 0" class="text-center pt-2">
          <v-btn
            id="btn"
            color="primary"
            class="mr-1"
            @click="downloadIP"
            download
          >
            Descargar
          </v-btn>
        </div>
      </v-col>
      <v-col cols="10" sm="9" md="9">
        <label
          >Cargar archivos para ips (Nombres de columnas de Excel: Permitidas,
          Denegadas y Advertidas):
          <input id="filesIP" ref="fileIP" @change="getFileIP" type="file" />
        </label>
        <v-btn
          v-if="this.filesIP.length != 0"
          icon
          @click="convertFileIP"
          x-large
          color="grey"
        >
          <v-icon>mdi-upload</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from "firebase";
import { host } from "./Tools/variables";
import axios from "axios";

export default {
  name: "#app",

  data() {
    return {
      modeloBloqueo: false,
      search: "",
      searchIp: "",
      headers: [
        {
          text: "Direcciones IP",
          sortable: true,
          value: "direcciones",
        },
        {
          text: "Docente",
          sortable: true,
          value: "nombre",
        },
        {
          text: "Bloquear usuario (Módulo en construcción)",
          sortable: true,
          value: "actions",
        },
      ],
      ips: [],
      getRta: false,
      count: 0,
      showIp: false,
      filesIP: [],
      tieneFileIP: false,
      base64IP: "",
    };
  },

  created() {
    document.title = "IPs";
    this.initialize();
  },

  methods: {
    initialize() {
      // IPS
      this.ips = [];
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "consultar");
          axios.post(host + "/ip", fd).then((result) => {
            let data = result.data.lista;
            this.ips = data;
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    bloquearIp(data) {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "denegar");
          fd.append("direccionIp", data);
          axios.post(host + "/ip", fd).then((result) => {
            let data = result.data;
            if (data.text != "OK") {
              this.$alert("Error al bloquear esta IP, inténtelo nuevamente");
            } else {
              this.initialize();
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    advertirIp(data) {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "advertir");
          fd.append("direccionIp", data);
          axios.post(host + "/ip", fd).then((result) => {
            let data = result.data;
            if (data.text != "OK") {
              this.$alert("Error al advertir esta IP, inténtelo nuevamente");
            } else {
              this.initialize();
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    permitirIp(data) {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "permitir");
          fd.append("direccionIp", data);
          axios.post(host + "/ip", fd).then((result) => {
            let data = result.data;
            if (data.text != "OK") {
              this.$alert("Error al permitir esta IP, inténtelo nuevamente");
            } else {
              this.initialize();
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    filterIP() {
      var hasNumber = /\d/;
      let v = hasNumber.test(this.searchIp);
      if (v == true) {
        if (this.searchIp.length > 2) {
          this.buscarIP(this.searchIp);
        }
      }
    },

    buscarIP(search) {
      if (this.count <= 1) {
        firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then((idToken) => {
            const fd = new FormData();
            fd.append("token", idToken);
            fd.append("tipo", "buscar");
            fd.append("ip", search);
            axios.post(host + "/ip", fd).then((result) => {
              let data = result.data;
              if (data.text == "OK") {
                this.ips = [];
                this.ips = data.lista;
                this.showIp = true;
              }
            });
          })
          .catch(function (error) {
            this.$alert("Error " + error.message);
          });
      }
    },

    download(content, fileName) {
      const url = URL.createObjectURL(
        new Blob([content], {
          type: "application/vnd.ms-excel",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName + "- IPs");
      document.body.appendChild(link);
      link.click();
    },

    downloadIP() {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "descarga");
          axios.post(host + "/ip", fd).then((result) => {
            let fecha = this.$moment(Date.now()).format("YYYY/MM/DD");
            this.download(result.data, fecha);
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    getFileIP() {
      this.filesIP = [];
      this.filesIP = this.$refs.fileIP.files[0];
    },

    convertFileIP() {
      var url = this.filesIP;
      var reader = new FileReader();
      reader.readAsDataURL(url);
      reader.onload = () => {
        this.base64IP = reader.result;
        console.log(this.base64IP);
        firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then((idToken) => {
            const fd = new FormData();
            fd.append("token", idToken);
            fd.append("tipo", "subir_ips");
            fd.append("archivo", this.base64IP);
            axios.post(host + "/ip", fd).then((result) => {
              let data = result.data;
              if (data.text == "OK") {
                this.initialize();
                this.$alert("Archivos cargados correctamente");
              } else {
                this.$alert("Error: " + result.data);
              }
            });
          })
          .catch(function (error) {
            this.$alert("Error " + error.message);
          });
      };
      reader.onerror = function (error) {
        this.$alert(error);
      };
    },
  },
};
</script>

<style>
</style>
